*, :before, :after {
  box-sizing: border-box;
}

body, h1, h2, h3, h4, p, figure, blockquote, dl, dd {
  margin: 0;
}

html, body {
  height: 100%;
}

ul[role="list"], ol[role="list"] {
  list-style: none;
}

html:focus-within {
  scroll-behavior: smooth;
}

body {
  text-rendering: optimizespeed;
  line-height: 1.5;
}

a:not([class]) {
  -webkit-text-decoration-skip-ink: auto;
  text-decoration-skip-ink: auto;
}

img, picture {
  max-width: 100%;
  display: block;
}

input, button, textarea, select {
  font: inherit;
}

@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *, :before, :after {
    scroll-behavior: auto !important;
    transition-duration: .01ms !important;
    animation-duration: .01ms !important;
    animation-iteration-count: 1 !important;
  }
}

:root {
  --scree-border-radius: 8px;
  --scree-color-light: white;
  --scree-color-dark: black;
}

body {
  font-family: Unbounded, cursive;
}

.wrapper {
  height: 100%;
  width: 100%;
  background-image: url("background.faaf9882.jpg");
  background-position: center;
  background-size: cover;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding-top: 20%;
  display: flex;
}

.wrapper h1 {
  background-color: var(--scree-color-dark);
  border-top-left-radius: var(--scree-border-radius);
  border-bottom-right-radius: var(--scree-border-radius);
  color: var(--scree-color-light);
  text-align: center;
  padding: 16px;
}

.wrapper .logos {
  background-color: var(--scree-color-light);
  border-top-left-radius: var(--scree-border-radius);
  border-bottom-right-radius: var(--scree-border-radius);
  justify-content: center;
  align-items: center;
  gap: 16px;
  padding: 16px;
  display: flex;
}

/*# sourceMappingURL=index.fa89c65b.css.map */
