@import 'reset';

:root {
    --scree-border-radius: 8px;
    --scree-color-light: white;
    --scree-color-dark: black;
}

body {
    font-family: 'Unbounded', cursive;
}

.wrapper {
    align-items: center;
    background-image: url('../assets/background.jpg');
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    gap: 16px;
    // justify-content: center;
    padding-top: 20%;
    height: 100%;
    width: 100%;

    h1 {
        background-color: var(--scree-color-dark);
        border-top-left-radius: var(--scree-border-radius);
        border-bottom-right-radius: var(--scree-border-radius);
        color: var(--scree-color-light);
        padding: 16px;
        text-align: center;
    }
    
    .logos {
        align-items: center;
        background-color: var(--scree-color-light);
        border-top-left-radius: var(--scree-border-radius);
        border-bottom-right-radius: var(--scree-border-radius);
        display: flex;
        gap: 16px;
        justify-content: center;
        padding: 16px;
    }
}